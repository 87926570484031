<template>
    <a @mousedown="downloadFile" class="download">Export PDF</a>
</template>
  
<script>
  import html2pdf from "html2pdf.js";
  
  export default {
    props: ["dom", "name"],
    methods: {
      downloadFile() {
        const me = this;
  
        const invoice = document.querySelector(me.dom);
        var opt = {
          margin: 1,
          filename: me.name,
        };
        html2pdf().from(invoice).set(opt).save();
      },
    },
  };
</script>
  
<style scoped>
  .download:hover {
      text-decoration: underline !important;
  }
  .download {
    background: none;
    box-shadow: none;
    color: blue !important;
  }
</style>